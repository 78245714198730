import {GatsbyImage} from "gatsby-plugin-image";
import React from 'react';
import classNames from 'classnames';
import * as styles from './ClippedImage.module.scss';

const directions = {
    toDown(height) {
        return {
            clipPath: `polygon(0 0, 100% ${height}, 100% 100%, 0 calc(100% - ${height}))`
        }
    },
    toUp(height) {
        return {
            clipPath: `polygon(0 ${height}, 100% 0, 100% calc(100% - ${height}), 0 100%)`
        }
    }
}

export function ClippedImage({
                                 image,
                                 className,
                                 slopeHeight = '100px',
                                 useCss = false,
                                 direction = "toDown",
                                 ...otherProps
                             }) {
    const attributes = {
        className
    };
    if (useCss) {
        attributes.className = classNames(className, styles.root, styles[`direction_${direction}`]);
    } else {
        attributes.style = directions[direction](slopeHeight);
    }
    return <GatsbyImage image={image.gatsbyImageData} alt={"Image"} {...otherProps} {...attributes} />;
}
