import React from 'react';
import * as styles from './PromoImages.module.scss';
import classNames from 'classnames';
import {GatsbyImage, getImage} from "gatsby-plugin-image";

export function PromoImages({className, children, ...otherProps}) {
    return <div className={classNames(className, styles.root)} {...otherProps}>
        <div className={styles.content}>
            {children}
        </div>
    </div>
}

PromoImages.Image = ({className, image, ...otherProps}) => {
    return (
        <GatsbyImage
            className={classNames(className, styles.image)}
            image={getImage(image)}
            {...otherProps}
            style={{position: 'absolute'}}
            alt="Promo image"/>
    );
}
