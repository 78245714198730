import React from 'react';
import classNames from 'classnames';
import * as styles from './AboutsUsSecondSection.module.scss';
import {PromoImages} from "../PromoImages";
import {MarkdownRenderer} from "../MarkdownRenderer";

export function AboutUsSecondSection({content, images, className, ...otherProps}) {
    return <section className={classNames(className, styles.root)} {...otherProps}>
        <MarkdownRenderer content={content} className={styles.content}/>
        <PromoImages className={styles.images}>
            {images.map((image, index) => {
                return <PromoImages.Image image={image} key={index}/>;
            })}
        </PromoImages>
    </section>
}
